
import { Component, Prop } from 'vue-property-decorator';
import BaseTable from '@/components/BaseTable.vue';
import BasePagination from '@/components/base/BasePagination.vue';
import { RoleUser } from '@/store/modules/role/types/role.types';
import { mixins } from 'vue-class-component';
import { PaginationMixin } from '@/components/base/pagination.mixin';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  components: { BasePagination, BaseTable }
})
export default class RoleUsersList extends mixins(PaginationMixin) {
  @Prop() public items!: RoleUser[];
  @Prop(Number) public currentPage!: number;
  @Prop(Number) public totalNumberOfPage!: number;
  @Prop(Number) public totalCount!: number;
  @Prop(Boolean) public isFirstPage!: boolean;
  @Prop(Boolean) public isLastPage!: boolean;
  @Prop(Number) public startItemIndex!: number;
  @Prop(Number) public endItemIndex!: number;

  private querySubject: Subject<string> = new Subject<string>();
  private roleUsersSearchInput: string = '';

  mounted() {
    this.querySubject.pipe(debounceTime(500)).subscribe((query) => {
      this.$emit('filterRoleUsersList', query);
    });
  }

  private searchRoleUsersList(query: string): void {
    this.roleUsersSearchInput = query;
    this.querySubject.next(query);
  }

  private get roleUsers(): RoleUser[] {
    return this.items || [];
  }

  private userGroupsMarkup(
    groups: Array<{ id: number; name: string }>
  ): string {
    if (!Array.isArray(groups)) {
      return '-';
    }

    let markup = '';
    groups.forEach((group) => {
      if (group && group.id) {
        markup += `<a href="/admin/group/${group.id}" target="_blank">${group.name}</a>`;
      }
    });

    if (!markup) {
      return '-';
    }

    return markup;
  }

  private removeUserFromRole(data: RoleUser) {
    this.$emit('removeUserFromRole', data);
  }
}
