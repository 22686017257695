
import { Component, Prop } from 'vue-property-decorator';
import BasePagination from '@/components/base/BasePagination.vue';
import BaseTable from '@/components/BaseTable.vue';
import { RoleGroup } from '@/store/modules/role/types/role.types';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { PaginationMixin } from '@/components/base/pagination.mixin';
import { mixins } from 'vue-class-component';

@Component({
  components: { BasePagination, BaseTable }
})
export default class RoleGroupsList extends mixins(PaginationMixin) {
  @Prop() public items!: RoleGroup[];
  @Prop(Number) public currentPage!: number;
  @Prop(Number) public totalNumberOfPage!: number;
  @Prop(Number) public totalCount!: number;
  @Prop(Boolean) public isFirstPage!: boolean;
  @Prop(Boolean) public isLastPage!: boolean;
  @Prop(Number) public startItemIndex!: number;
  @Prop(Number) public endItemIndex!: number;

  private querySubject: Subject<string> = new Subject<string>();
  private roleGroupsSearchInput: string = '';

  mounted() {
    this.querySubject.pipe(debounceTime(500)).subscribe((query) => {
      this.$emit('filterRoleGroupsList', query);
    });
  }

  private searchRoleGroupsList(query: string): void {
    this.roleGroupsSearchInput = query;
    this.querySubject.next(query);
  }

  private get roleGroups(): RoleGroup[] {
    return this.items || [];
  }

  private removeGroupFromRole(data: RoleGroup) {
    this.$emit('removeGroupFromRole', data);
  }

  private groupMarkup(group: { id: number; name: string }): string {
    if (!group) {
      return '-';
    }

    let markup = '';
    if (group && group.id) {
      markup += `<a href="/admin/group/${group.id}" target="_blank">${group.name}</a>`;
    }

    if (!markup) {
      return '-';
    }

    return markup;
  }
}
